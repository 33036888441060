import { useContext, useMemo } from "react";

import { OrderLogContext } from "contexts/orders";

export default function useWeekComparison() {
  const { currentWeek, lastWeek, currentWeekLastYear } = useContext(OrderLogContext);

  const numberOfDaysToCompare = useMemo(() => currentWeek.length, [currentWeek.length]);

  const currentWeekOrderCount = useMemo(() => {
    return currentWeek.reduce((totalCount: number, orderLog: OrderLog) => totalCount + orderLog.orderCount, 0);
  }, [currentWeek]);
  const currentWeekOrderTotalOrderSum = useMemo(() => {
    return currentWeek.reduce((totalSum: number, orderLog: OrderLog) => totalSum + orderLog.orderTotalSum, 0);
  }, [currentWeek]);

  const lastWeekOrderCount = useMemo(() => {
    return lastWeek.slice(0, numberOfDaysToCompare).reduce((totalCount: number, orderLog: OrderLog) => totalCount + orderLog.orderCount, 0);
  }, [lastWeek, numberOfDaysToCompare]);
  const lastWeekOrderTotalOrderSum = useMemo(() => {
    return lastWeek.slice(0, numberOfDaysToCompare).reduce((totalSum: number, orderLog: OrderLog) => totalSum + orderLog.orderTotalSum, 0);
  }, [lastWeek, numberOfDaysToCompare]);

  const lastYearOrderCount = useMemo(() => {
    return currentWeekLastYear.slice(0, numberOfDaysToCompare).reduce((totalCount: number, orderLog: OrderLog) => totalCount + orderLog.orderCount, 0);
  }, [currentWeekLastYear, numberOfDaysToCompare]);
  const lastYearOrderTotalOrderSum = useMemo(() => {
    return currentWeekLastYear.slice(0, numberOfDaysToCompare).reduce((totalSum: number, orderLog: OrderLog) => totalSum + orderLog.orderTotalSum, 0);
  }, [currentWeekLastYear, numberOfDaysToCompare]);

  const countComparedToLastWeek = useMemo(() => {
    let count = currentWeekOrderCount - lastWeekOrderCount;
    let percentage = count === 0 ? 0 : (count / lastWeekOrderCount) * 100;
    return { value: count, percentage };
  }, [currentWeekOrderCount, lastWeekOrderCount]);

  const sumComparedToLastWeek = useMemo(() => {
    let sum = currentWeekOrderTotalOrderSum - lastWeekOrderTotalOrderSum;
    let percentage = sum === 0 ? 0 : (sum / lastWeekOrderTotalOrderSum) * 100;
    return { value: sum, percentage };
  }, [currentWeekOrderTotalOrderSum, lastWeekOrderTotalOrderSum]);

  const countComparedToLastYear = useMemo(() => {
    let count = currentWeekOrderCount - lastYearOrderCount;
    let percentage = count === 0 ? 0 : (count / lastYearOrderCount) * 100;
    return { value: count, percentage };
  }, [currentWeekOrderCount, lastYearOrderCount]);

  const sumComparedToLastYear = useMemo(() => {
    let sum = currentWeekOrderTotalOrderSum - lastYearOrderTotalOrderSum;
    let percentage = sum === 0 ? 0 : (sum / lastYearOrderTotalOrderSum) * 100;
    return { value: sum, percentage };
  }, [currentWeekOrderTotalOrderSum, lastYearOrderTotalOrderSum]);

  return {
    countComparedToLastWeek,
    sumComparedToLastWeek,

    countComparedToLastYear,
    sumComparedToLastYear,
  };
}
